<template>
    <button class="btn-timeout c-primary fw-700" id="batalkan" ref="batalkan" @click="cancel">
        {{textButton}} - {{timeCountDownButton}}<span class="fw-500"> s</span>
    </button>
</template>
<script>
export default {
  props: {
    time: {
      type: Number,
      default: 10
    },
    textButton: {
      type: String,
      default: 'OK'
    }
  },
  data () {
    return {
      timeCountDownButton: 0
    }
  },
  mounted () {
    this.timeCountDownButton = this.time
    const timeInterval = setInterval(() => {
      if (this.timeCountDownButton <= 0) {
        clearInterval(timeInterval)
        this.$emit('click', true)
      } else {
        this.timeCountDownButton--
      }
    }, 1000)
  }
}
</script>
