<template>
    <Modal  :modalActive="contextBinding.modalUnit" :class-padding="'modal-inner-qr'">
         <div style="text-align: center">
          <p class="fs-52 fw-700 c-deep">{{$l.detectLanguage('pindaiQR')}}</p>
          <img  class="mb-36" ref="image" :src="image" />
          <p class="fs-38 fw-600 c-primary my-0">{{$l.detectLanguage('arahkanQR')}}</p>
          <p class="fs-38 fw-600 c-primary mb-40">{{$l.detectLanguage('padaScanner')}}</p>
          <button-time-out :time="contextBinding.config.delay.timeOutCancel" :text-button="'Batalkan'" @click="showHideModalCashier"></button-time-out>
          <p class="text-unit-line fw-600 fs-24 cgray-B">{{$l.detectLanguage('atau')}} <span @click="createTicket">{{$l.detectLanguage('cetak tiket')}} {{selectUnit}}</span></p>
         </div>
    </Modal>
    <Modal  :modalActive="contextBinding.modalSingle">
         <div style="text-align: center">
          <img  class="mb-24"  src="@/assets/img/information.png" />
           <p class="fs-52 fw-600 c-deep">{{$l.detectLanguage('informationKios')}}</p>
           <div class=" my-5 px-1">
             <template v-if="!contextBinding.isDataNotPendaftaran">
              <p class="fs-38 fw-500 c-primary text-left" >
                {{$l.detectLanguage('Scan QR tiket antrian berhasil')}} <br>
                <span v-html="changeOfData($l.detectLanguage('processWithParam'), contextBinding.listUnit[0].name, '$$')"></span>
              </p>
             </template>
             <template v-else>
              <p class="fs-38 fw-500 c-primary text-left">
                {{ $l.detectLanguage('Anda belum melakukan pendaftaran') }}<br>
              </p>
              <p class="fs-38 fw-700 c-primary text-left  mt-60" v-if="!contextBinding.OnlyPrintTicket">
                {{ $l.detectLanguage('Apakah Anda yakin ingin mencetak tiket dan check-in ke') }} <strong>{{contextBinding.selectUnitData.name}}</strong> {{ $l.detectLanguage('queue line') }}
              </p>
              <p class="fs-38 fw-700 c-primary text-left  mt-60" v-else>
                {{ $l.detectLanguage('Apakah Anda yakin ingin check-in ke') }} <strong>{{contextBinding.selectUnitData.name}}</strong> {{ $l.detectLanguage('queue line') }}
              </p>
             </template>
           </div>
           <div class="row">
            <div class="col-6">
              <button-linear-cancel :data-time-out="contextBinding.config.delay" @cancel="backToMenu" ref="button-cancel"/>
            </div>
            <div class="col-6">
              <button @click="afterClick" class="btn-lanjutkan btn-block fs-42 fw-600">{{$l.detectLanguage('proceed')}}</button>
            </div>
           </div>
      </div>
    </Modal>
    <Modal  :modalActive="contextBinding.modalOther">
         <div style="text-align: center">
          <img src="@/assets/img/information.png" />
           <p class="fs-52 fw-600 c-deep">{{$l.detectLanguage('informationKios')}}</p>
           <div class="text-left pr-5 my-5">
              <p class="fs-42 fw-500 c-primary">
                {{$l.detectLanguage('Scan QR tiket antrian berhasil')}}
                {{$l.detectLanguage('Silakan pilih tujuan antrian Anda')}}:
              </p>
           </div>
           <div class="btn-unit mb-5" v-for="item, index in contextBinding.modalListConfirm" :key="index" @click="selectList(item)">
              <label class="text-left fs-52 fw-700 c-primary ">
                {{item.name}}
              </label>
              <img src="@/assets/icon/chevron-right.svg" alt="">
           </div>
           <button-time-out :time="contextBinding.config.delay.timeOutCancel" :text-button="'Batalkan'" @click.stop="closeModal"></button-time-out>
      </div>
    </Modal>
    <div class="margin-x-60 " :class="contextBinding.modalUnit|| contextBinding.modalOther || contextBinding.modalSingle ? 'filter-blur': ''">
        <div class="grid-Data-header">
          <div>
              <p class="fs-62 fw-700 m-0 c-deep" @click="modalNext = true">{{$l.detectLanguage('welcome')}},</p>
              <p class="fs-42 fw-600 m-0 c-primary">{{$l.detectLanguage('pilihTujuan')}}</p>
          </div>
          <div class="translate">
              <span class="fs-32 fw-600 id" :class="activeLang === 'id' ? 'active': ''" @click="change('id')">ID</span>
              <span class="fs-32 fw-600 en" :class="activeLang === 'id' ? '': 'active'" @click="change('en')">EN</span>
          </div>
        </div>
        <div class="display-menu-show">
          <card-menu
            :label-header="card"
            v-for="card, index in contextBinding.listMenu" :key="index" @click.stop="handleEvent(card)" :active-lang="activeLang"/>
        </div>
          <!-- :key="index" @click="handleEventComponent(card.action)"/> -->
    </div>
    <info-animation-down :class=" contextBinding.modalOther || contextBinding.modalSingle ? 'filter-blur': ''" :text-info="$l.detectLanguage('scanQR')"  :is-listen-qr="true"/>
</template>

<script>
import CardMenu from '@/components/Menu/CardMenu'
import InfoAnimationDown from '@/components/Button/InfoAnimationDown'
import Modal from '@/components/Modal'
import ButtonTimeOut from '@/components/Button/ButtonTimeOut'
import { mapActions, mapState } from 'vuex'
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import ENUM_SETUP from '@/enum/typeSetup'
import MoreFunction from '@/helpers/moreFunction'
export default {
  props: {
    contextBinding: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    CardMenu,
    InfoAnimationDown,
    Modal,
    ButtonTimeOut,
    ButtonLinearCancel
  },
  computed: {
    ...mapState('DocumentHandler', {
      cardComponent: state => state.cardComponent
    }),
    selectUnit () {
      const selectedUnit = this.contextBinding.selectUnitData
      return selectedUnit.name
    }
  },
  data () {
    return {
      modalActiveKasir: false,
      modalNext: false,
      modalOther: false,
      activeLang: 'id',
      activeUnit: ['Radiologi', 'Kasir'],
      image: require('@/assets/img/standing_kiosk.gif')
    }
  },
  // watch: {
  //   'contextBinding' (val) {
  //     if (val.modalSingle) {
  //       document.addEventListener('click', this.reset)
  //     }
  //   }
  // },
  unmounted () {
    document.removeEventListener('click', this.reset)
    this.emitter.off('change-component', (data) => {
      this.changeCardfromLocalStorage()
    })
  },
  mounted () {
    this.activeLang = localStorage.Language ? localStorage.Language : 'id'
    document.addEventListener('click', this.reset)
    this.setCardComponent()
    this.emitter.on('change-component', (data) => {
      this.changeCardfromLocalStorage()
    })
  },
  methods: {
    changeOfData (lang, text, change) {
      const moreFunction = new MoreFunction()
      return moreFunction.changeLangwithParam(lang, text, change)
    },
    reset () {
      if (this.contextBinding.modalSingle) {
        this.$refs['button-cancel'].resetBackgroundLinearButton()
      }
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    backToMenu () {
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
      // this.modalNext = false
    },
    createTicket () {
      window.machineKiosk.send({
        type: 'create:ticket:unit',
        data: {
          type: ENUM_SETUP.TYPE_UNIT
        }
      })
    },
    changeCardfromLocalStorage (data) {
      this.setCardComponent()
    },
    change (lang) {
      this.setChangeLang(lang)
      this.activeLang = lang === 'id' ? 'id' : 'en'
      localStorage.setItem('Language', lang)
    },
    ...mapActions({
      setCardComponent: 'DocumentHandler/SET_CARD_COMPONENTS',
      setChangeLang: 'Language/CHANGE_LANGUAGE'
    }),
    showHideModalCashier () {
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
      // this.modalActiveKasir = !this.modalActiveKasir
    },
    toCreateAntrian () {
      this.$router.push({ name: 'showTicket' })
    },
    handleEventComponent (evt) {
      if (evt === 'TO_INPUT_KEYPAD') {
        this.$router.push({ name: 'inputKeypad' })
      } else if (evt === 'TO_PRINT') {
        this.$router.push({ name: 'showTicket' })
      } else if (evt === 'SHOW_KASIR') {
        this.modalActiveKasir = !this.modalActiveKasir
        setTimeout(() => {
          this.$refs.image.setAttribute('src', this.image)
        }, 10)
      }
    },
    handleEvent (context) {
      window.machineKiosk.send({
        type: 'select:menu',
        data: {
          type: context.type,
          list: context.child
        }
      })
    },
    selectList (context) {
      window.machineKiosk.send({
        type: 'select:confirmation',
        data: context
      })
    },
    closeModal () {
      document.removeEventListener('click', this.reset)
      window.machineKiosk.send({
        type: 'button:cancel'
      })
    },
    afterClick () {
      window.machineKiosk.send({
        type: 'button:lanjutkan'
      })
    }
  }
}
</script>
<style>
  .btn-unit{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px #CFDFF5;
    border-radius: 40px;
    padding: 58px 36px 59px 50px;
    display: grid;
    grid-template-columns: auto 40px;
  }
</style>
