<template>
    <div style="position: relative; margin-top: 120px">
    <div class="kiosk-header-card">
      <img class="pb-3" :src="icon" >
      <span class="fs-38 fw-600 pl-1">
        {{header}}
      </span>
    </div>
    <div class="kiosk-card">
        <div class="my-auto">
            <p class="fs-62 fw-700 mb-0" v-html="listMenu">
            </p>
        </div>
        <div class="m-auto">
            <img src="@/assets/icon/chevron-right.svg" alt="">
        </div>
    </div>

    </div>
</template>

<script>
export default {
  computed: {
    header () {
      if (this.activeLang === 'en') {
        return this.labelHeader.header_en
      }
      return this.labelHeader.header
    },
    listMenu () {
      if (this.activeLang === 'en') {
        return this.labelHeader.en
      }
      return this.labelHeader.id
    },
    icon () {
      if (this.labelHeader && this.labelHeader.icon === 'kasir') {
        return this.unit
      }
      return this.pendaftaran
    }
  },
  props: {
    activeLang: {
      type: String,
      default: 'id'
    },
    labelHeader: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pendaftaran: require('@/assets/icon/pendaftaran.svg'),
      unit: require('@/assets/icon/kasir.svg')
    }
  }
}
</script>
