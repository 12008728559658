<template>
  <menu-kiosk :context-binding="context" />
</template>

<script>
import MenuKiosk from './Menu.vue'
import { useSelector } from '@xstate/vue'
export default {
  components: {
    MenuKiosk
  },
  data () {
    const selectorState = (state) => {
      const selectUnit = state.context.selectUnit && state.context.selectUnit.name ? state.context.selectUnit : (state.context.selectUnit && state.context.selectUnit.list ? state.context.selectUnit.list[0] : state.context.listUnit[0])

      return {
        ...state.context,
        selectUnitData: selectUnit
      }
    }
    const context = useSelector(window.machineKiosk, selectorState)
    return {
      context
    }
  }
}
</script>

<style>

</style>
